<template>
	<div class="row m-0">
      <div class="d-flex">
         <div class="text-start align-self-center mr-4">
            <img class="bannerPacote" :src="pesquisa.resultado.nomeArquivo" width="150" height="200"/>
         </div>
         <div class="w-100 mr-5">
            <div class="d-flex">
               <h3 class="color-theme font-weight-bold mb-0">{{pesquisa.resultado.nome}}</h3>
            </div>                          
            <p class="limitador-5">{{pesquisa.resultado.descricao}}</p>
            <a class="border-bottom cursor-pointer" :href="pesquisa.resultado.linkDocumentacao" target="_blank" v-if="pesquisa.resultado.linkDocumentacao != null && pesquisa.resultado.linkDocumentacao != ''">Ver a documentação</a>
         </div>              
      </div>
      <hr class="w-100">
      <div class="col-6 mt-2" v-if="pesquisa.resultado.link != null && pesquisa.resultado.link != ''">
         <iframe class="rounded" width="100%" height="400" 
            :src="pesquisa.resultado.link" 
            title="Video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media;" 
            allowfullscreen v-if="String(pesquisa.resultado.link).startsWith('https://www.youtube.com/embed/') || String(pesquisa.resultado.link).startsWith('https://iframe.mediadelivery.net/embed/')">
         </iframe>
      </div>
      <div v-if="pesquisa.resultado.fotos != null" class="col-6">
         <div id="carouselBanners" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
               <div class="carousel-item active">
                  <div class="mb-2">
                     <div class="container">
                        <div class="row align-items-start">
                           <div class="col">
                              <div class="align-center mb-2 mt-2">
                                 <img :src="pesquisa.resultado.fotos[0].fotoUrlImage" alt="Start image"  width="700" height="400">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="carousel-item">
                  <div class="mb-2">
                     <div class="container">
                        <div class="row align-items-start">
                           <div class="col">
                              <div class="align-center mb-2 mt-2">
                                 <img :src="pesquisa.resultado.fotos[1].fotoUrlImage" alt="Start image"  width="700" height="400">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="carousel-item">
                  <div class="mb-2">
                     <div class="container">
                        <div class="row align-items-start">
                           <div class="col">
                              <div class="align-center mb-2 mt-2">
                                 <img :src="pesquisa.resultado.fotos[2].fotoUrlImage" alt="Start image"  width="700" height="400">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <a class="carousel-control-prev" href="#carouselBanners" role="button" data-slide="prev">
               <span class="carousel-control-prev-icon" aria-hidden="true"></span>
               <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselBanners" role="button" data-slide="next">
               <span class="carousel-control-next-icon" aria-hidden="true"></span>
               <span class="sr-only">Next</span>
            </a>
         </div>
      </div>
   </div>
</template>

<script>

// import $ from 'jquery'
// import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Classroom',
	data: function() {
		return {
         pesquisa: {'resultado': []},
         scriptAtual: null
		}
	},
   created() {
      this.scriptAtual = this.$route.params.idScript;
      this.searchAllFromProduto(this.moduloAtual);
   },
	computed: {
		...mapState({
         idUsuario:  state => state.idUsuario,
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
      searchAllFromProduto : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFromProduto',
            params: {
               id: this.scriptAtual,
               idUsuario: this.idUsuario
            }
         }).then(response => {
				ref.pesquisa.resultado = response.data[0];

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      fecharModulo : function() {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admMeusScripts');
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
	},
   mounted() {
      
   }
}

</script>

<style scoped>
.cardCurso {
	width: 100%;
	height: 100%;
}

.cardModulo {
   min-height: 100%;
   height: auto; 
}

.cardAula {
   border: solid 1px rgb(90, 90, 90);
   border-radius: 4px;
}

.w-special {
width: 100%;
}

.cardAula:hover {
   transform: scale(1.009);
   /* -webkit-box-shadow: 0px 0px 4px 1px var(--primary-color); */
   border: solid 1px var(--primary-color);

}

.divisao {
   border-bottom: 1px solid rgb(131, 131, 131);
   min-width: 600px;
}

.borderRight {
   border-right: 1px solid rgb(131, 131, 131);
}

.titleCurso h1 {
	text-transform: uppercase;
	font-size: 50px;
	line-height: 70px;
	margin-bottom: 20px;
	font-weight: bold;
}

.titleCurso h1 span {
   color: #dd0f20;
}

.titleCurso p {
	font-weight: 400;
	font-size: 18px;
	padding-left: 20px;
	border-left: 3px solid #dd0f20;
	line-height: 28px;
	margin: 40px 0;
}

/* .imgBanner {
	background-size: auto;
	background-image: url('@/assets/admin/images/inicio/background-banner.png');
} */

.cardDados {
   height: 426px;
}
</style>